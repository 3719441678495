import React from 'react'
import styled from 'styled-components'
import { grid, breakpoint } from 'src/theme/grid'

import AddsUrl from 'src/assets/images/ico-ads.png'
import BookingUrl from 'src/assets/images/ico-booking.png'
import CommentsUrl from 'src/assets/images/ico-comments.png'
import NotifyUrl from 'src/assets/images/ico-notify.png'
import OfferUrl from 'src/assets/images/ico-offer.png'
import PaymentsUrl from 'src/assets/images/ico-payments.png'
import SupportUrl from 'src/assets/images/ico-support.png'
import UiUrl from 'src/assets/images/ico-ui.png'
import CertificateUrl from 'src/assets/images/bg-tcc.jpg'

import BackgroundImage from 'gatsby-background-image'

const BOXES_COLORS = {
  white: '#ffffff',
  blue: '#19a3bd',
  green: '#438a44',
  red: '#b24b4f'
}

const BOXES_IMAGES = {
  adds: AddsUrl,
  booking: BookingUrl,
  comments: CommentsUrl,
  notify: NotifyUrl,
  offer: OfferUrl,
  payments: PaymentsUrl,
  support: SupportUrl,
  ui: UiUrl
}

export const Banner = styled.div`
  display: flex;
  background-color: #f5f5f3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${({ theme }) => `calc(90vh - ${theme.dimensions.topBarHeight})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  &::before,
  &::after {
    filter: opacity(60%);
  }
`

export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 110px 7%;
  background-color: #f5f5f3;
  ${breakpoint.m`
  flex-direction: row`}
`

export const BannerMobileUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #242d3f;
  ${breakpoint.sm`
  flex-direction: row`}
`

export const BannerSocial = styled.div`
  // min-height: 90vh;
  background-color: #242d3f;
  box-sizing: border-box;
  padding: 3.2rem 0;
  &::before,
  &::after {
    filter: none;
  }
`

export const BannerContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.dimensions.topBarHeight})`};
`

export const MainTitle = styled.h1`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #242d3f;
  margin-bottom: 1.2rem;
  text-align: center;
  box-sizing: border-box;
  padding: 0 0.6rem;
  ${breakpoint.sm`
    font-size: 3.1rem;
  `}
`

export const MobileUserTitle = styled(MainTitle)`
  color: white;
  font-size: 1.6rem;
  ${breakpoint.sm`
    font-size: 2.4rem;
  `}
`

export const BoxesSection = styled.section`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 7%;
  background-color: #242d3f;
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.dimensions.topBarHeight})`};
`

export const CompaniesSection = styled(BoxesSection)`
  min-height: 65vh;
  background-color: #f5f5f3;
`

export const BoxesTitle = styled.h3`
  font-size: 2.3rem;
  color: white;
  width: 100%;
  text-align: center;
  padding: 2.2em 0;
  box-sizing: border-box;
`

export const CompaniesTitle = styled(BoxesTitle)`
  color: rgb(36, 45, 63);
`

const BoxComponent = ({ className, image, title, description }) => (
  <div className={className}>
    <div>
      <img src={BOXES_IMAGES[image]} alt='' />
      <h5>{title}</h5>
      <span>{description}</span>
    </div>
  </div>
)

export const Box = styled(BoxComponent)`
  height: 275px;
  background-color: ${({ backgroundColor }) => BOXES_COLORS[backgroundColor]};
  color: ${({ textColor }) => BOXES_COLORS[textColor]};
  ${grid.breakpoints({ df: 12, s: 6, m: 3 }, 12, '0px')};
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 22px;
  transition: all 0.3s;
  border: 2px solid #373e4c;
  ${breakpoint.m`
    border: none;
  `}
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 110px;
    height: auto;
  }
  > div {
    position: relative;
    top: 70px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  h5 {
    margin-top: 10px;
    font-size: 18px;
    display: flex;
    width: 100%;
    text-align: center;
    font-weight: 700;
    height: 80px;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    > div {
      top: 30px;
    }
    &:before {
      opacity: 0;
    }
    span {
      opacity: 1;
    }
  }
`

export const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 4rem 0;
`

export const StatisticsSection = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 4.6rem 8%;
  background-color: #1d96af;
  min-height: 400px;
  align-items: center;
`
export const CertificateStatisticsSection = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 4.6rem 8%;
  background-image: url(${CertificateUrl});
  min-height: 400px;
  align-items: center;
`

export const CircleRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${breakpoint.m`
    flex-direction: row;
  `}
`
export const BannerMobileRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // ${breakpoint.m`
  //   flex-direction: column;
  // `}
`

export const Circle = styled.div`
  display: flex;
  width: 250px;
  height: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0 4%;
  margin-bottom: 3.2rem;
  ${breakpoint.m`
    width: 290px;
    height: 290px;
    margin-bottom: 0;
  `}
`

export const CircleText = styled.span`
  display: inline-block;
  color: white;
  font-weight: 600;
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
  ${breakpoint.m`
    font-size: 0.875rem;
  `}
`

export const CircleNumber = styled.span`
  display: inline-block;
  color: white;
  font-weight: 400;
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
  padding: 0.6rem 0;
  ${breakpoint.sm`
    font-size: 2.3rem;
  `}
`

export const StoreImageWrapper = styled.div`
  display: inline-block;
  margin: 0.3rem;
  max-width: 120px;
  width: 100%;
  ${breakpoint.sm`
    margin: 1.2rem;
    max-width: 162px;
  `}
`

export const TrustedCompanyBox = styled(BackgroundImage)`
  height: 140px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  ${grid.breakpoints({ df: 12, s: 6, m: 3 }, 12, '20px')};
  margin-bottom: 1.2rem;
  background-color: white;
`

export const GazeleLogo = styled(BackgroundImage)`
  height: 120px;
  margin: 3.6rem auto;
  background-size: 30%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50%;
  ${grid.breakpoints({ df: 12, s: 12, m: 12 }, 12, '20px')};
`

export const GazeleLogo2 = styled.img`
  display: block;
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`

export const Logo = styled.img`
  display: block;
  height: 43px;
  width: auto;
  margin: 0.8rem auto;
  margin-bottom: 1.2rem;
`
export const EUFinancingLogo = styled.img`
  display: block;
  height: auto;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`

export const FeaturesSection = styled.div`
  display: flex;
  background-color: #f5f5f3;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
`
const FeatureComponent = ({
  reverse,
  className,
  title,
  description,
  image
}) => {
  return (
    <div className={className}>
      <div className='text'>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className='space'></div>
      <div className='image'>
        <img src={image} alt='image' />
      </div>
    </div>
  )
}

export const Feature = styled(FeatureComponent)`
  padding: 1rem 8%;
  display: flex;
  flex-direction: column;
  flex: 100%;
  ${breakpoint.m`
    flex-direction: row;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `}
  img {
    box-shadow: 0px 0px 45px 22px rgba(223, 223, 231, 1);
    object-fit: fill;
    width: 100%;
  }
  .space {
    flex: 8.3%;
  }
  .text {
    display: flex;
    flex-direction: column;
    flex: 41%;
    align-items: left;
    justify-content: center;
    padding: 20px 0 20px 0;
  }
  .image {
    flex: 50%;
    padding: 20px 0 20px 0;
  }
`

export const FeaturesTitle = styled(BoxesTitle)`
  color: rgb(36, 45, 63);
`
